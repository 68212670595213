<template>
    <div class="shop">
        <div class="container">
          <!-- Confetti Container -->
          <div id="confetti-container"></div>
            <PaymentModal
                ref="payment_modal"
                :user-gems="userGems"
                :user-coins="userCoins"
            />

            <div
                class="tab-pane fade position-relative"
                :class="{ 'show active': isActive }"
                id="nav-in-game-shop"
                role="tabpanel"
                aria-labelledby="nav-in-game-shop"
                tabindex="3"
            >
                <!-- Account Topup Action -->
                <div class="topup">
                    <div class="gem">
                        <img
                            src="/assets/images/shop/icons/gem.png"
                            alt="Gem Icon"
                        />
                        <span>{{ userGems }}</span>
                        <b-button
                            class="btn-add"
                            type="button"
                            @click="activateChildTab(1)"
                            >+</b-button
                        >
                    </div>

                    <div class="coin">
                        <img
                            src="/assets/images/shop/icons/coin.png"
                            alt="Coin Icon"
                        />
                        <span> {{ userCoins }}</span>
                        <b-button
                            class="btn-add"
                            type="button"
                            @click="activateChildTab(0)"
                            >+</b-button
                        >
                    </div>
                </div>

                <b-tabs content-class="category">
                    <!-- BIKE -->
                    <b-tab title="Bike" active>
                        <Bike
                            @activateModalParent="activateChildTab"
                            :frames="frames"
                            :user-gems="userGems"
                            :user-coins="userCoins"
                            :wheels="wheels"
                        />
                    </b-tab>

                    <!-- AVATAR -->
                    <b-tab v-if="false" title="AVATAR">
                        <Avatar
                            @activateModalParent="activateChildTab"
                            :head="head"
                            :hair="hair"
                            :facial-hair="facialHair"
                            :user-gems="userGems"
                            :user-coins="userCoins"
                        />
                    </b-tab>

                    <!-- APPAREL -->
                    <b-tab title="APPAREL">
                        <Apparel
                            @activateModalParent="activateChildTab"
                            :gloves="gloves"
                            :socks="socks"
                            :shoes="shoes"
                            :jerseys="jerseys"
                            :glasses="glasses"
                            :helmet="helmet"
                            :user-gems="userGems"
                            :user-coins="userCoins"
                        />
                    </b-tab>
                </b-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import Bike from "./Bike.vue";
import Avatar from "./Avatar.vue";
import Apparel from "./Apparel.vue";
import { mapGetters } from "vuex";
import PaymentModal from "./PaymentModal.vue";

export default {
    name: "GameShop",
    props: ["isActive", "userGems", "userCoins"],

    components: {
        Bike,
        Avatar,
        Apparel,
        PaymentModal,
    },
    computed: {
        ...mapGetters("gameshop", [
            "frames",
            "wheels",
            "head",
            "hair",
            "shoes",
            "jerseys",
            "facialHair",
            "glasses",
            "gloves",
            "helmet",
            "socks",
            "gems_bundles",
            "coins_bundles",
        ]),
    },
    data() {
        return {
            selectedPackage: null,
            activeTab: 0,
        };
    },
    methods: {
        activateChildTab(tabIndex) {
            this.$refs.payment_modal.activateTab(tabIndex);
        },
        onPackageModalOpen() {
            this.getBundleInfo();
        },
        selectPackage(index) {
            this.selectedPackage = index;
        },

        async getRedirectUrl(type) {
            try {
                // Validate type
                if (!["coins", "gems"].includes(type)) {
                    throw new Error("Invalid type provided");
                }

                let payload = {};

                if (type === "coins") {
                    if (
                        !this.coins_bundles ||
                        !this.coins_bundles[this.selectedPackage]
                    ) {
                        throw new Error("Invalid coins package selected");
                    }
                    payload = {
                        package_id:
                            this.coins_bundles[this.selectedPackage].Identifier,
                    };
                } else if (type === "gems") {
                    if (
                        !this.gems_bundles ||
                        !this.gems_bundles[this.selectedPackage]
                    ) {
                        throw new Error("Invalid gems package selected");
                    }
                    payload = {
                        package_id:
                            this.gems_bundles[this.selectedPackage].Identifier,
                    };
                }

                const { data } = await this.$store.dispatch(
                    "gameshop/getRedirectUrl",
                    payload
                );

                if (data.session_url) {
                    window.location.href = data.session_url;
                } else {
                    throw new Error("Session URL not found in response");
                }
            } catch (error) {
                console.error("Error getting redirect URL:", error);
                alert(
                    "Unable to process at this moment. Please try again later."
                );
            }
        },
        //getShopItems
        getShopItems() {
            this.$store.dispatch("gameshop/getAllGarageItems");
        },
        getBundleInfo() {
            this.$store.dispatch("gameshop/getBundleInfo");
        },
    },
    created() {
        document.body.classList.add("garage");
        this.getShopItems();
    },
};
</script>

<style>
 .custom-swal-icon {
  width: 5em !important;
  height: 5em !important;
}
/* Confetti Effect */
.confetti {
  position: fixed;
  top: 0;
  width: 10px;
  height: 10px;
  background: #FFC700;
  animation: confettiFall linear infinite;
  z-index: 9999;
}

@keyframes confettiFall {
  0% {
    opacity: 1;
    transform: translateY(0) rotate(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100vh) rotate(360deg);
  }
}

#confetti-container {
  pointer-events: none;
  overflow: hidden;
}
.shop {
    background-color: #eff4fa;
    padding: 35px 0;
    position: relative;
}

.topup {
    align-items: center;
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}

@media (min-width: 992px) {
    .topup {
        position: absolute;
    }
}

.topup .gem,
.topup .coin {
    align-items: center;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex: 1 1 auto;
    font-size: 18px;
    height: 44px;
    justify-content: space-between;
    padding: 5px;
    width: 200px;
}

.topup .gem {
    margin-right: 30px;
}

.topup img {
    margin-left: 15px;
    margin-right: 10px;
}

.topup .btn-add {
    background-color: #00b2e3;
    border-radius: 4px;
    border: 0;
    color: #ffffff;
    cursor: pointer;
    height: 34px;
    line-height: 34px;
    margin-left: auto;
    outline: none;
    padding: 0;
    transition: all ease-in-out 0.3s;
    width: 34px;
}

.topup .btn-add:focus,
.topup .btn-add:hover {
    background-color: #0390b8;
}

.shop .tab-content {
    background-color: #f6f8fb;
    /* background: rgba(252, 253, 255, 0.65);
    backdrop-filter: blur(5px); */
}

.shop .tab-content.category > .tab-pane {
    padding: 32px 26px;
}

.shop .nav-tabs {
    border: none;
}

.shop .nav-tabs .nav-item {
    margin-bottom: 0;
    width: auto;
}

.shop .nav-tabs .nav-link {
    background-color: #ffffff;
    border-color: #b8c3d1;
    border-radius: 0;
    color: #252e39;
    font-family: Roboto;
    font-size: 18px;
    height: 58px;
    line-height: 32px;
    padding: 12px 16px;
    text-align: center;
    text-transform: uppercase;

    width: 215px;
}

.shop .nav-tabs .nav-link.active,
.shop .nav-tabs .nav-link:hover {
    border-color: #fcb913;
    font-size: 18px;
}

.shop .v-nav {
    width: 225px;
}

.shop .v-nav .nav-item {
    margin-bottom: 13px;
}

.shop .v-nav .nav-link {
    background-color: rgba(255, 255, 255, 0.6);
    background-image: url("/assets/images/shop/icons/caret-right.png");
    background-position: right 15px center;
    background-repeat: no-repeat;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.75);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    color: #36425b;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    padding: 6px 16px;
    text-transform: uppercase;
}

.shop .nav-pills .nav-link:first-child {
    border-top: 1px solid rgba(255, 255, 255, 0.75);
}

.shop .v-nav .nav-link.active {
    background-color: #ffffff;
    border: 1px solid #8193af;
    /* box-shadow: none; */
    color: #36425b;
}

/* Mobile Responsive - CSS Style */
@media (max-width: 991.98px)  {
    .shop .topup {
        position: relative;
        margin-bottom: 20px;
    }
}

@media (max-width: 767.98px) {
    .garage .overview-tab .nav-tabs {
        flex-wrap: nowrap;
        overflow-x: auto;
    }

    .shop {
        padding-top: 20px;
    }

    .topup .gem,
    .topup .coin {
        border-radius: 0;
        box-shadow: none;
        height: 50px;
    }

    .topup .gem {
        margin: 0;
    }

    .topup .gem::after {
        background-color: #e2e6eb;
        content: "";
        display: block;
        height: 36px;
        position: relative;
        right: -6px;
        width: 1px;
    }

    .shop .nav-tabs {
        flex-wrap: nowrap;
        margin-left: -14px;
        margin-right: -14px;
        overflow-x: auto;
    }

    .shop .nav-tabs .nav-item {
        flex: 1 0 auto;
    }

    .shop .nav-tabs .nav-link {
        min-width: 120px;
        width: 100%;
    }

    .shop .tab-content.category > .tab-pane {
        padding: 0;
    }

    .shop .tabs.row {
        flex-direction: column;
    }

    .shop .col-auto {
        display: flex;
        flex: none;
        justify-content: center;
        width: 100%;
    }

    .shop .tabs.row .tab-content {
        flex-basis: auto;
    }

    .shop .category .v-nav {
        flex-direction: row !important;
        flex-wrap: nowrap;
        margin: 20px 0;
        overflow-x: auto;
        width: auto;
    }

    .shop .v-nav .nav-item {
        margin: 0;
        width: auto;
    }

    .shop .v-nav .nav-item + .nav-item {
        margin-left: 15px;
    }

    .shop .v-nav .nav-link {
        background-image: none;
        border-radius: 8px;
        line-height: 20px;
        padding: 4px 12px;
        text-align: center;
        width: 125px;
    }
}
</style>
